import React, { memo, useMemo } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { type FormValues } from '../../../FormValuesInterfaces'
import {
  type Program,
  type Result
} from '../../../OutputInterfaces'
import {
  type GetBenefitEligibility,
  type GetYearlyOrMonthlyAmount,
  type MonthYearToggleButton,
  type RenderRow,
  type RenderRowWithHeading,
  type RenderTableHead,
  type SectionVisibilityType
} from '../../../interfaces'

interface Props {
  formValues: FormValues
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  tableVisibility: SectionVisibilityType
  renderRow: RenderRow
  getBenefitEligibility: GetBenefitEligibility
  toggleTableBody: (value: string) => void
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
  renderRowsWithHeading: RenderRowWithHeading
  renderTableHead: RenderTableHead
  sectionsVisibility: SectionVisibilityType
}

const ExpenseAccountSection: React.FC<Props> = memo((props) => {
  const {
    result,
    monthYearToggleButton,
    tableVisibility,
    toggleTableBody,
    getBenefitEligibility,
    getMonthlyOrYearlyAmount,
    renderTableHead,
    sectionsVisibility
  } = props

  const renderOppRows = useMemo(() => (
    monthYearToggleValue: string,
    program: Program,
    programIndex: number,
    paddings?: string
  ) => {
    const { title, key, current_annual } = program
    const benefitEligibility = getBenefitEligibility(current_annual, monthYearToggleValue)

    const renderTitle = (title: string): JSX.Element => {
      const formattedTitle = title ?? '--'

      const formattedBenefitEligibility = benefitEligibility

      return (
        <>
          <TableCell component='th' scope='row' style={{ paddingLeft: paddings }}>
            {formattedTitle}
          </TableCell>

          <TableCell align='right'>{formattedBenefitEligibility}</TableCell>
        </>
      )
    }

    return (
      <React.Fragment key={programIndex}>
        <TableRow key={key}>{renderTitle(title)}</TableRow>
      </React.Fragment>
    )
  }, [getBenefitEligibility])

  const renderExpenseAccoutsRow = useMemo(() => (expenses: any[]) => {
    return expenses.map((expense: any, key: number) => {
      return renderOppRows('monthlyExpensesAccountCalculations', expense, key, '50px')
    })
  }, [renderOppRows])

  const renderDependentCareFsa = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('dependentCareFsa') }}>
            <b>
              {tableVisibility.dependentCareFsa ? <b>-</b> : <b>+</b>} Dependent Care FSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.dependentcare_fsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.dependentCareFsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.dependentcare_fsa.programs)
              )}
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.dependentCareFsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const renderHealthCareFsa = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareFsa') }}>
            <b>
              {tableVisibility.healthCareFsa ? <b>-</b> : <b>+</b>} Health Care FSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_fsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareFsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_fsa.programs)
              )}
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareFsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const renderHealthSavingAmount = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareHsa') }}>
            <b>
              {tableVisibility.healthCareHsa ? <b>-</b> : <b>+</b>} Health Care HSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_hsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareHsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_hsa.programs)
              )}
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareHsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const renderHealthRembursment = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareHra') }}>
            <b>
              {tableVisibility.healthCareHra ? <b>-</b> : <b>+</b>} Health Reimbursment Account
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_hra?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareHra && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_hra.programs)
              )}
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareHra,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  return (
    <>
      <div className='d-flex justify-content-end'>
        {monthYearToggleButton('monthlyExpensesAccountCalculations')}
      </div>
      <TableContainer component={Paper}>
        <Table>
          {renderTableHead(
            'sectionExpenseAccount',
            'monthlyExpensesAccountCalculations',
            'Expense Account',
            result?.output?.expense_account_section?.programs_sum
          )}

          {renderDependentCareFsa}
        </Table>

        <Table>
          {renderHealthCareFsa}
        </Table>

        <Table>
          {renderHealthSavingAmount}
        </Table>

        <Table>
          {renderHealthRembursment}
        </Table>
      </TableContainer>
    </>
  )
})

export default ExpenseAccountSection
