import React, { type Context, type Dispatch, useContext, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import {
  Button,
  CircularProgress,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import {
  BENEFITS_ELIGIBILITY,
  INCOME_CURRENT,
  PROGRAMS_CALCULATIONS,
  ROW_KEY_CHILD_SUPPORT,
  ROW_KEY_LIFELINE,
  ROW_KEY_PHONE_EXPENSE,
  ROW_KEY_TANF,
  ROW_KEY_WIC,
  ROW_KEY_EMPLOYER_HEALTH_INSURANCE,
  ROW_KEY_BUS_FARE_PASS,
  SECTIONS,
  TABLES,
  ROW_KEY_PREK,
  ROW_KEY_CHILDCARE_EXPENSE,
  ROW_KEY_SECTION8,
  ROW_KEY_RENT_EXPENSES,
  ROW_KEY_POTENTIAL_CTC,
  ROW_KEY_SNAP,
  ROW_KEY_FOOD_EXPENSES,
  PREK_AGE_MAX,
  ROW_KEY_CCDF
} from '../../Constants'

import '../../component_styles/BenefitsCliff.sass'

import AnchorLinks from './AnchorLinks'
import BenefitsEligibilitySection from './OutcomeScreen/BenefitsEligibilitySection'
import CurrentFinances from './OutcomeScreen/CurrentFinances'
import FinancialSnapshot from './OutcomeScreen/FinancialSnapshot'
import MonthlyExpenses from './OutcomeScreen/MonthlyExpenses'
import TotalAnnualTaxCreditsSection from './OutcomeScreen/TotalAnnualTaxCreditsSection'
import { postAllData } from '../../api/benefitCliffs'

import { type FormValues } from '../../FormValuesInterfaces'
import { type ChildCareProgram, type Program, type Result } from '../../OutputInterfaces'
import { type ProgramCalculationType, type SectionVisibilityType } from '../../interfaces'
import { addThousandsSeparator } from '../../Helper'
import NPSSurvey from '../Employee/NPSSurveyModal'
import SurveyCompletion from '../Employee/SurveyCompletionModal'
import { cookieExists, cookieHasExpired } from '../Employee/helpers/NpsFeedback'
import { postRequestNpsFeedback } from '../../api/npsFeedback'
import ExpenseAccountSection from './OutcomeScreen/ExpenseAccountSection'

const _ = require('lodash')

interface StepEightProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  step: number
  userId?: number
}

interface TableVisibility {
  [key: string]: boolean;
}

type ToggleTableBody = (section: string) => void
interface RenderChildCareExpensesProps {
  benefits: Program,
  monthYearToggleValue: string,
  paddings?: string,
  toggleTableBody: ToggleTableBody
  tableVisibility: TableVisibility
}

const StepEight: React.FC<StepEightProps> = (props) => {
  const formValues = useContext<FormValues>(props.formValuesContext)
  const [result, setResult] = useState<Result>()
  const [sectionsVisibility, setSetionsVisibility] = useState<SectionVisibilityType>(SECTIONS)
  const [tableVisibility, setTableVisibility] = useState<SectionVisibilityType>(TABLES)
  const [monthlyOryearly, setMonthlyOrYearly] = useState<ProgramCalculationType>(PROGRAMS_CALCULATIONS)
  const [isNPSSurveyOpen, setIsNPSSurveyOpen] = useState(false)
  const [isSurveyCompletionOpen, setSurveyCompletionOpen] = useState(false)
  const [askForFeedback, setAskForFeedback] = useState(false)
  const [npsRating, setNpsRating] = useState(0)
  const [cookieCreated30DaysAgo, setCookieCreated30DaysAgo] = useState(true)

  useEffect(() => {
    if (cookieExists('parsleyNPSFeedback') && !cookieHasExpired('parsleyNPSFeedback')) {
      setCookieCreated30DaysAgo(false)
    } else {
      setCookieCreated30DaysAgo(true)
    }
  }, [])

  useEffect(() => {
    void askForNPSFeedback()
  }, [])

  const askForNPSFeedback = async (): Promise<void> => {
    if (props.userId) {
      const response = await postRequestNpsFeedback(undefined, props.userId)
      setAskForFeedback(response?.ask_nps_feedback)
    }
  }

  useEffect(() => {
    const getData = () => _.omit(formValues, INCOME_CURRENT)
    const benefits_cliff_calculator = {
      house_hold_data: {
        ...getData()
      }
    }

    postAllData({ benefits_cliff_calculator }, setResult)
  }, [])

  const handleSubmit = () => { window.print() }

  const handleSubmitNpsRating = () => {
    setIsNPSSurveyOpen(true)
  }

  const getMonthlyOrYearlyAmount = (amount: number | string | boolean | undefined, value: string = 'monthly'): string => {
    const parsedAmount = Number(amount)

    if (!isNaN(parsedAmount)) {
      const dividedAmount = monthlyOryearly[value] === 'monthly' ? parsedAmount / 12 : parsedAmount
      const formattedAmount = dividedAmount.toFixed(2)
      return addThousandsSeparator(formattedAmount)
    }

    return addThousandsSeparator('0.00')
  }

  const getBenefitEligibility = (value: string | number | boolean | undefined, monthYearValue?: string): string | number | undefined =>
    typeof value === 'boolean' ? (value ? 'Eligible' : 'Ineligible') : BENEFITS_ELIGIBILITY.includes(String(value)) || typeof value === 'string' ? value : '$' + getMonthlyOrYearlyAmount(value, monthYearValue)

  const toggleSections = (value: string): void => {
    setSetionsVisibility(sectionsVisibility => ({
      ...sectionsVisibility,
      [value]: !sectionsVisibility[value]
    }))
  }

  const toggleTableBody = (value: string): void => {
    setTableVisibility(tableVisibility => ({
      ...tableVisibility,
      [value]: !tableVisibility[value]
    }))
  }

  const handlMonthOrYear = (value: string, target: EventTarget): void => {
    setMonthlyOrYearly((monthlyOryearly) => ({
      ...monthlyOryearly,
      [value]: (target as HTMLInputElement).value
    }))
  }

  const monthYearToggleButton = (monthYearToggleValue: string): JSX.Element => (
    <ToggleButtonGroup
      color='primary'
      value={monthlyOryearly[monthYearToggleValue]}
      exclusive
      onChange={(e) => { handlMonthOrYear(monthYearToggleValue, e.target) }}
      aria-label='toggle button group'
    >
      <ToggleButton value='monthly' aria-label='monthly'> Monthly </ToggleButton>
      <ToggleButton value='yearly' aria-label='yearly'> Yearly </ToggleButton>
    </ToggleButtonGroup>
  )

  const renderTableHead = (sectionVisibility: string, monthYearToggleValue: string, heading: string, programSum: number): JSX.Element => (
    <TableHead>
      <TableRow>
        <TableCell
          className='cursor-pointer'
          onClick={() => { toggleSections(sectionVisibility) }}
        >
          <h3>{sectionsVisibility[sectionVisibility] ? <b>-</b> : <b>+</b>}  {heading}</h3>
        </TableCell>

        <TableCell align='right'>
          <h3>${getMonthlyOrYearlyAmount(programSum, monthYearToggleValue)}</h3>
        </TableCell>
      </TableRow>
    </TableHead>
  )
  const renderRow = (
    tableVisibilityValue: string,
    monthYearToggleValue: string,
    headingText: string,
    program: Program[],
    programIndex: number,
    note?: string | JSX.Element,
    outputComputed?: number | string,
    paddings?: string,
    header_padding?: string
  ): JSX.Element => {
    return (
      <React.Fragment key={programIndex}>
        <TableRow>
          <TableCell
            className='cursor-pointer'
            component='th'
            scope='program'
            onClick={() => { toggleTableBody(tableVisibilityValue) }}
            style={{ paddingLeft: header_padding }}
          >
            <b>{tableVisibility[tableVisibilityValue] ? <b>-</b> : <b>+</b>} {headingText}</b>
          </TableCell>

          <TableCell align='right'>
            <b>{outputComputed === 'Benefit Outcome' ? '' : '$' + getMonthlyOrYearlyAmount(outputComputed, monthYearToggleValue)}</b>
          </TableCell>
        </TableRow>

        {tableVisibility[tableVisibilityValue] && (
          <>
            {headingText !== 'Living Expenses'
              ? tableVisibility[tableVisibilityValue] && program.length > 0 &&
              program.map((benefits: Program, key: number) => (
                renderDefaultRow(monthYearToggleValue, benefits, key, paddings)
              ))
              : (
                <>
                  <TableRow>
                    <TableCell colSpan={2} style={{ paddingLeft: paddings }}>
                      {note}
                    </TableCell>
                  </TableRow>
                  {program.map((benefits: Program, key: number) => (
                    <React.Fragment key={key}>
                      {benefits.child_care_expenses ?
                        renderChildCareExpenses({
                          benefits,
                          monthYearToggleValue,
                          paddings,
                          toggleTableBody,
                          tableVisibility
                        })
                        : renderDefaultRow(monthYearToggleValue, benefits, key, paddings)}
                    </React.Fragment>
                  ))}

                </>
              )
            }
          </>
        )}
      </React.Fragment>
    )
  }


  const renderChildCareExpenses: React.FC<RenderChildCareExpensesProps> = ({
    benefits,
    monthYearToggleValue,
    paddings,
    toggleTableBody,
    tableVisibility,
  }) => {
    return (
      <>
        <TableRow>
          <TableCell
            role="cell" scope="program"
            style={{ paddingLeft: paddings }}
            className='cursor-pointer'
            onClick={() => { toggleTableBody('childcare_expenses') }}
          >
            <b>
              {tableVisibility['childcare_expenses'] ? <b>-</b> : <b>+</b>} Child Care Expenses
            </b>
          </TableCell>
          <TableCell align='right'>
            ${getMonthlyOrYearlyAmount(benefits.child_care_expenses?.programs_sum || 0, monthYearToggleValue)}
          </TableCell>
        </TableRow>
        {tableVisibility['childcare_expenses'] && benefits.child_care_expenses?.programs.map((childProgram, childKey) => (
          <TableRow key={childKey}>
            <TableCell role="cell" scope="program" style={{ paddingLeft: '72px' }}>
              {childProgram.title}
            </TableCell>
            <TableCell align='right'>
              ${getMonthlyOrYearlyAmount(childProgram.current_annual, monthYearToggleValue)}
            </TableCell>
          </TableRow>
        ))}
      </>
    )
  }


  const renderEligibilityStatus = (): JSX.Element => {
    return <p>Ineligible</p>
    
    // return formValues.isCopaymentPeriodExitPhase
    //   ? <p>Ineligible</p>
    //   : <p>Ineligible at Next Renewal</p>
  }

  const renderDefaultRow = (monthYearToggleValue: string, program: ChildCareProgram, programIndex: number, paddings?: string): JSX.Element => {
    const { title, key, current_annual, copay_amount, additional_parent_share, wisconsin_shares_subsidy_eligibility, additional_parent_share_note } = program
    const benefitEligibility = getBenefitEligibility(current_annual, monthYearToggleValue)

    const renderBenefitEligibility = (): string | number | JSX.Element | JSX.Element[] | undefined => {
      if (key === 'ccdf') {
        if (wisconsin_shares_subsidy_eligibility) {
          return (
            <React.Fragment key={programIndex}>
              <p>Subsidy paid to provider: {getBenefitEligibility(current_annual, monthYearToggleValue)}</p>
              <p>Copay: {getBenefitEligibility(copay_amount, monthYearToggleValue)}</p>
              <p>Additional Parent Share: {getBenefitEligibility(additional_parent_share, monthYearToggleValue)}</p>
              <p><i>{additional_parent_share_note}</i></p>
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={programIndex}>
              {renderEligibilityStatus()}
            </React.Fragment>
          )
        }
      }
      if (key === 'prek') {
        return (
          result?.output?.child_members.map((child) => (
            <>
              {((formValues.childrenCount === 1 && child?.age > PREK_AGE_MAX) || (formValues.childrenCount >= 1 && child?.age <= PREK_AGE_MAX)) && <p>Head Start/Early Head Start: {child?.name}: {getEligibilityStatus(child?.headstart_early_headstart_eligibility)}</p>}
              {child?.prek_eligibility && <p>Four-Year-Old Kindergarten (4-K): {child?.name}: {getEligibilityStatus(child?.prek_eligibility)}</p>}
            </>
          ))
        )
      }
      if (key === ROW_KEY_SECTION8) {
        return (
          <>
            {benefitEligibility}
            {Number(program.max_tenant_share) !== 0 &&
              <p>Max Tenant Share: {isNaN(Number(program.max_tenant_share)) ? program.max_tenant_share : `$${Number(program.max_tenant_share).toFixed(2)}`}</p>
            }
          </>
        )
      }
      if (key === 'wic' && current_annual === 0) {
        return <p>Ineligible</p>
      }
      if (key === ROW_KEY_SNAP) {
        return (
          <>
            {benefitEligibility}
            <p>{program?.eligibilty_note}</p>
          </>
        )
      }

      return benefitEligibility
    }

    const getEligibilityStatus = (eligibility: boolean): string => eligibility ? 'Eligible' : 'Ineligible'

    const hasNote = (ROW_KEY_TANF === key && result?.output?.tanf_note) ||
      (ROW_KEY_WIC === key && result?.output?.wic_note) ||
      (ROW_KEY_CHILD_SUPPORT === key && result?.output?.child_support_note) ||
      (ROW_KEY_LIFELINE === key && result?.output?.lifeline_benefit_note) ||
      (ROW_KEY_PHONE_EXPENSE === key && result?.output?.lifeline_expense_note) ||
      (ROW_KEY_EMPLOYER_HEALTH_INSURANCE === key && result?.output?.employer_health_insurance_availability_note) ||
      (ROW_KEY_EMPLOYER_HEALTH_INSURANCE === key && result?.output?.employer_health_insurance_premium_note) ||
      (ROW_KEY_BUS_FARE_PASS === key && result?.output?.bus_pass_expense_note) ||
      (ROW_KEY_PREK === key && result?.output?.headstart_early_headstart_note) ||
      (ROW_KEY_CHILDCARE_EXPENSE === key && result?.output?.ccdf_expense_note) ||
      (ROW_KEY_CHILDCARE_EXPENSE === key && result?.output?.childcare_headstart_cost_note) ||
      (ROW_KEY_SECTION8 === key && result?.output?.section8_benefit_note) ||
      (ROW_KEY_RENT_EXPENSES === key && result?.output?.section8_expense_note) ||
      (ROW_KEY_POTENTIAL_CTC === key && result?.output?.ctc_notes) ||
      (ROW_KEY_SNAP === key && result?.output?.snap_benefit_note) ||
      (ROW_KEY_FOOD_EXPENSES === key && result?.output?.snap_expense_note) ||
      (ROW_KEY_CCDF === key && result?.output?.wisconsin_shares_copayment_period_note)

    return (
      <React.Fragment key={programIndex}>
        <TableRow key={key}>
          <TableCell component='th' scope='program' style={{ paddingLeft: paddings }}>
            {title ?? '--'}
          </TableCell>

          <TableCell align='right'>
            {renderBenefitEligibility()}
          </TableCell>
        </TableRow>

        {hasNote && (
          <TableRow>
            <TableCell colSpan={2} style={{ paddingLeft: paddings }}>
              {ROW_KEY_TANF === key && <i>{result?.output?.tanf_note}</i>}
              {ROW_KEY_WIC === key && <i>{result?.output?.wic_note}</i>}
              {ROW_KEY_CHILD_SUPPORT === key && <i>{result?.output?.child_support_note}</i>}
              {ROW_KEY_LIFELINE === key && <i>{result?.output?.lifeline_benefit_note}</i>}
              {ROW_KEY_PHONE_EXPENSE === key && <i>{result?.output?.lifeline_expense_note}</i>}
              {ROW_KEY_EMPLOYER_HEALTH_INSURANCE === key && <p><i>{ReactHtmlParser(result?.output?.employer_health_insurance_availability_note)}</i></p>}
              {ROW_KEY_EMPLOYER_HEALTH_INSURANCE === key && <p><i>{result?.output?.employer_health_insurance_premium_note}</i></p>}
              {ROW_KEY_BUS_FARE_PASS === key && <i>{result?.output?.bus_pass_expense_note}</i>}
              {ROW_KEY_PREK === key && <i>{result?.output?.headstart_early_headstart_note}</i>}
              {ROW_KEY_CHILDCARE_EXPENSE === key && <i>{result?.output?.ccdf_expense_note}</i>}
              {ROW_KEY_CHILDCARE_EXPENSE === key && <i>{result?.output?.childcare_headstart_cost_note}</i>}
              {ROW_KEY_SECTION8 === key && <i>{ReactHtmlParser(result?.output?.section8_benefit_note)}</i>}
              {ROW_KEY_RENT_EXPENSES === key && <i>{ReactHtmlParser(result?.output?.section8_expense_note)}</i>}
              {ROW_KEY_POTENTIAL_CTC === key && (
                <>
                  {result?.output?.ctc_notes.map((note: string, index: number) => (
                    <p key={index}>{ReactHtmlParser(note)}</p>
                  ))}
                </>
              )}
              {ROW_KEY_SNAP === key && <i>{ReactHtmlParser(result?.output?.snap_benefit_note)}</i>}
              {ROW_KEY_FOOD_EXPENSES === key && <i>{result?.output?.snap_expense_note}</i>}
              {/* {ROW_KEY_CCDF === key && <i>{result?.output?.wisconsin_shares_copayment_period_note}</i>} */}

            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  const renderRowsWithHeading = (tableVisibilityValue: string, monthYearToggleValue: string, program: Program, key: number, paddings?: string): JSX.Element => {
    const { title, current_annual } = program

    const toggleButton = (
      <div className='cursor-pointer' onClick={() => { toggleTableBody(tableVisibilityValue) }}>
        {tableVisibility[tableVisibilityValue] ? <b>-</b> : <b>+</b>} <b>{title ?? '--'}</b>
      </div>
    )

    return (
      <TableRow key={key}>
        <TableCell component='th' scope='program' style={{ paddingLeft: paddings }}>
          {toggleButton}
        </TableCell>

        <TableCell align='right'>
          {
            formValues?.adults[0]?.dependencyCount as number > 0
              ? (
                <>
                  <b>Credit Applied per pay period based on your withholding selections</b>
                  <br />
                  (${getMonthlyOrYearlyAmount(current_annual, monthYearToggleValue)} not included here)
                </>
              )
              : (
                <b>${getMonthlyOrYearlyAmount(current_annual, monthYearToggleValue)}</b>
              )
          }
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {!result && <div className='circular-progress'> <CircularProgress /> </div>}

      {result && (
        <div className='table'>
          <FinancialSnapshot
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            getMonthlyOrYearlyAmount={getMonthlyOrYearlyAmount}
          />

          <BenefitsEligibilitySection
            formValues={formValues}
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            renderRow={renderRow}
            renderDefaultRow={renderDefaultRow}
          />

          <TotalAnnualTaxCreditsSection
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            renderRow={renderRow}
            renderDefaultRow={renderDefaultRow}
            toggleTableBody={toggleTableBody}
            tableVisibility={tableVisibility}
            renderRowsWithHeading={renderRowsWithHeading}
            renderTableHead={renderTableHead}
            sectionsVisibility={sectionsVisibility}
          />

          <CurrentFinances
            formValues={formValues}
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            tableVisibility={tableVisibility}
            renderRow={renderRow}
            renderDefaultRow={renderDefaultRow}
            toggleTableBody={toggleTableBody}
            getMonthlyOrYearlyAmount={getMonthlyOrYearlyAmount}
          />

          <MonthlyExpenses
            formValues={formValues}
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            tableVisibility={tableVisibility}
            renderRow={renderRow}
            getBenefitEligibility={getBenefitEligibility}
            toggleTableBody={toggleTableBody}
            getMonthlyOrYearlyAmount={getMonthlyOrYearlyAmount}
            renderRowsWithHeading={renderRowsWithHeading}
            renderTableHead={renderTableHead}
            sectionsVisibility={sectionsVisibility}
          />

          <ExpenseAccountSection
            formValues={formValues}
            result={result}
            monthYearToggleButton={monthYearToggleButton}
            tableVisibility={tableVisibility}
            renderRow={renderRow}
            getBenefitEligibility={getBenefitEligibility}
            toggleTableBody={toggleTableBody}
            getMonthlyOrYearlyAmount={getMonthlyOrYearlyAmount}
            renderRowsWithHeading={renderRowsWithHeading}
            renderTableHead={renderTableHead}
            sectionsVisibility={sectionsVisibility}
          />
        </div>
      )}
      {isNPSSurveyOpen &&
        <NPSSurvey
          isOpen={isNPSSurveyOpen}
          setIsOpen={setIsNPSSurveyOpen}
          setSurveyCompletionOpen={setSurveyCompletionOpen}
          employeeID={formValues?.adults[0]?.employee?.id}
          userID={props?.userId}
          npsRating={npsRating}
          setNpsRating={setNpsRating}
        />
      }
      {isSurveyCompletionOpen && !isNPSSurveyOpen &&
        <SurveyCompletion
          isOpen={isSurveyCompletionOpen}
          setIsOpen={setSurveyCompletionOpen}
          npsRating={npsRating}
          setCookieCreated30DaysAgo={setCookieCreated30DaysAgo}
        />
      }

      {result && <div className='next-back-buttons'>
        <Button variant='contained' onClick={handleSubmit}>{'Print Results'}</Button>
        {cookieCreated30DaysAgo || askForFeedback
          ? (
            <Button variant='contained' onClick={handleSubmitNpsRating}>
              {'Submit Feedback'}
            </Button>
          )
          : null}
        <Button className={`${props.step < 1 ? 'disabled' : ''}`} onClick={props.handleBack}>{'Back'}</Button>
        <AnchorLinks handleSubmit={handleSubmit} handleBack={props.handleBack} step={props.step} />
      </div>}
    </>
  )
}

export default StepEight
export type { StepEightProps }
