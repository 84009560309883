import { toast } from 'react-toastify'

import { apiClient } from './apiClient'
import { handleRedirect } from '../Helper'
import I18n from '../config/I18n'

export const postAllData = (data: object, setResult: Function) => {
  apiClient
    .post(`/${I18n.locale as string}/benefits_cliff_calculators/post_data`, data,)
    .then(response => {
      toast.success('Result retreived successfully')
      setResult(response.data)
    })
    .catch(() => toast.error('Failed in result reterival'))
}

export const createGuestUser = (
  data: object,
  setPassword: Function,
  setIsLoading: Function,
  setEmailError: Function
) =>
  apiClient
    .post(`/${I18n.locale as string}/users/create_user_with_random_password`, data)
    .then(({ data }) => setPassword(data?.password))
    .catch(({ response }) => setEmailError({ showError: true, errorMsg: response?.data?.message }))
    .finally(() => setIsLoading(false))

export const signInGuestUser = async (data: object, setErrorMessage: Function) =>
  apiClient
    .post(`/${I18n.locale as string}/users/sign_in_user`, data)
    .then(({ data }) => {
      toast.success(data?.message)
      handleRedirect('en', () => { }, 'benefits_cliff_calculators/new')
    })
    .catch(({ response }) => setErrorMessage(response?.data?.message))
